.global-header {
  grid-area: head;
  height: 120px;
  padding-top: 1.6rem;
}

.global-main {
  grid-area: main;
  background: white;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.4rem 1.6rem;

  @media only screen and (min-width:700px) {
    padding: 4.8rem 1.6rem;
  }

  @media only screen and (min-width:1200px) {
    padding: 3.2rem;
  }

}

.global-footer {
  grid-area: foot;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  color: white;
  padding: 1.6rem;
  font-size: 1.2rem;
}

.profile {
  grid-area: profile;
  text-align: center;
  color: white;
  display: flex;
  justify-content: center;
  align-items: top;
  padding: 1.6rem;

  &__headshot {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
  }

  &__title {
    font-family: $heading-font-family;
    font-size: 2.8rem;
  }

  &__sub-title {
    border-top: 1px solid white;
    margin-top: 0.6rem;
    padding-top: 1rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 1px;
  }

  &__social {
    display: flex;
    justify-content: space-between;
    padding: 1rem 1.6rem;
  }

  &__link {
    background: transparent;
    line-height: 5.8rem;
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: 2px solid white;
    transition: all, 0.3s;

    &:hover {
      background: white;

      .profile__link_img {
        fill: $blue;
      }
    }

    &_img {
      fill: #fff;
      transition: all, 0.3s;
    }

    &-text {
      display: none;
    }

  }


}

.global-wrapper {
  justify-content: stretch;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'head'
    'profile'
    'main'
    'foot';
  min-height: 100vh;
  background-image:
  /* url(/assets/45-degree-fabric-light.png), */
  linear-gradient(to bottom, #52b5e3, #62a0ee, #9981e3, #cc56b9, #e51776);
  background-size: auto;

  @media only screen and (min-width:1200px) {
    grid-template-columns: 46rem 1fr;
    grid-template-areas:
      'head main'
      'profile main'
      'foot main';
  }

}

.content {
  position: relative;
  max-width: 80rem;

  &:last-of-type p {
    margin-bottom: 0;
  }
}

.highlight {
  color: $pink;
  font-weight: 900;
}

.global-logo {
  background: transparent url(/assets/squidge-inc-logo-white.svg) no-repeat center center;
  height: 10rem;
  background-size: auto 80%;
  text-indent: -9999px;
}

.headshot {
  width: 20rem;
  height: 20rem;
  position: absolute;
  top: -10.5rem;
  right: 0;
  border-radius: 50%;
  border: 2px solid $blue;
}

.cta {
  text-align: right;
}

a:not(.button):not(.profile__link):not(.modal__closer) {
  color: darken(#52b5e3, 25%);
  border-bottom: 1px solid $blue;
  transition: all 0.3s;

  &:hover {
    border-color: transparent;
    color: $blue;
  }
}

.button {
  position: relative;
  display: inline-block;
  background-image: linear-gradient(to right bottom, #52b5e3, #62a0ee, #9981e3, #cc56b9, #e51776);
  padding: 1.6rem;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  color: darken(#52b5e3, 25%);
  letter-spacing: 1px;
  border-radius: 26px;
  border: 0;
  min-width: 100%;
  z-index: 10;
  transition: all 0.3s;
  margin: 0.8rem 0;
  opacity: 1;
  cursor: pointer;

  @media only screen and (min-width:700px) {
    margin: 0.8rem;
    min-width: 20rem;
  }

  &:hover {
    transform: scale(0.98);
    opacity: 0.8;
  }

  &:after {
    position: absolute;
    top: 2px;
    right: 2px;
    left: 2px;
    bottom: 2px;
    content: '';
    background: white;
    z-index: -5;
    border-radius: 25px;
  }

  &[disabled="disabled"], &[disabled="disabled"]:hover {
    opacity: 0.3;
    transform: scale(1);
    color: white;
  }

  &[disabled="disabled"]:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1.2rem;
    margin-left: -1rem;
    display: block;
    content:'';
    width: 2rem;
    height: 2rem;
    border: 2px solid transparent;
    border-top-color: #e51776;
    border-radius: 50%;
    animation: spin .8s ease infinite;
    z-index: 100;
  }

}

@keyframes spin {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.formElement {
  margin: 1.6rem 0;
}

label {
  display: block;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.2rem;
  letter-spacing: 1px;
}

input {
  max-width: 38rem;
}

textarea {
  min-height: 20rem;
}

input, textarea {
  width: 100%;
  border: 1px solid #ccc;
  padding: 0.6rem 0.8rem;
  border-radius: 5px;

  &:focus {
    border-color: $blue;
  }
}

.disclaimer {
  font-size: 1.3rem;
  color: darken(#ccc, 40%);
}

// Modal

// Body class
.modalOpen {
	overflow: hidden;
	width: 100%;

	.global-wrapper {
    filter: blur(10px);
  }
}

.modal {
	position: fixed;
	z-index: 999;
	top: -100%;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
  height: 100%;
  padding: 1.6rem;
	background: rgba(255,255,255,0.5) url(#) top center;
	overflow-y: scroll;
	overflow-x: hidden;
	-webkit-overflow-scrolling: touch;
	opacity: 0;
  display: flex;
  align-items: flex-start;

	&.on {
		top:0;
		opacity: 1;

		.modal__wrapper {
      opacity: 1;
		}

	}

	.modal__closer {
		position: absolute;
    top: -4rem;
		right: -0.5rem;
		font-size: 3rem;
		cursor: pointer;
		text-decoration: none;
		color: darken(#52b5e3, 25%);
		font-size: 4.8rem;
		border: none;
		z-index: 99999;

    @media only screen and (min-width:700px) {
      top: -6rem;
  		right: -4rem;
    }
	}

	.modal__wrapper {
		background: white;
		max-width: 90rem;
		width: 100%;
		margin: auto;
		padding: 3.2rem 1.6rem;
    opacity: 0;

		@media only screen and (min-width:700px) {
			padding: 6.4rem;
		}

		border-radius: 10px;
    transition-timing-function: ease-in-out;
		transition: all 1s;
		box-shadow: 0 1px 3px 0 rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 2px 1px -1px rgba(0,0,0,.12);

	}

	.cta {
		text-align: right;
		padding-top: 0;

		.button {
			max-width: 15rem;
		}
	}

}

.toaster {
  display: block;
  background: $pink;
  z-index:9999;
  position:fixed;
  top:-100%;
  width:100%;
  padding:8px;
  color: white;
  text-align:center;
  -webkit-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
  box-shadow: 0 5px 8px 0 rgba(0,0,0,0.05);
  opacity: 0;
  transition: all 0.5s;

  &.open {
    opacity: 1;
    top: 0;
  }

  .toaster__title {
    display:block;
    font-size:14px;
  }

  .toaster__content {
    font-size:14px;
  }

  &.no {
    background: tomato;
    color: white;
  }

  &.thanks {
    background: $pink;
    color: white;
  }

  @media only screen and (min-width:800px) {
    padding:16px;

    .toaster__title {
      display:inline;
    }

  }

}
