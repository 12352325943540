@import	'_kit/normalise'; // Standardise elements
@import	'_kit/resets-helpers'; // Helper Classes

// Google Font Reference(s)
$font-varela-round: 'Varela Round', sans-serif;
$font-nunito-sans: 'Nunito Sans', sans-serif;

// Our variables
$base-font-family:	$font-nunito-sans;
$base-font-size:	2rem;
$base-font-weight:	400;
$base-line-height:	1.5;

$heading-font-family: $font-varela-round;

$base-text-color:	lighten(#4c4c4c, 15%);
$background-color: 	white;

$blue: #52B5E3;
$pink: #E51776;
$green: #A0C964;
$yellow: #FFB93D;

// Global Settings
html,
body {
	height: 100%;
}

html {
	font-size: 62.5%;
  background: white;
}

body {
	font-family: $base-font-family;
	font-weight: $base-font-weight;
	color: $base-text-color;
	text-rendering: optimizeLegibility;
  	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5;
	overflow-y:scroll;
	font-size: 1.6rem;

	@media only screen and (min-width:700px) {
		font-size: $base-font-size;
	}
}

a {

}

h1,
h2,
h3,
h4 {
	color: $pink;
	font-family: $heading-font-family;
	line-height: 1.2;
	margin-bottom: 0.5em;

	& + p {
		margin-top: 0;
	}
}

hr {
	border: 0;
	height: 1px;
	background: #ccc;
	margin: 1em 0;
}

// Art Direction Overrides & Trumps
@import 'art-direction';
